import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

function Usp() {
  return (
    <div className="section-pale pt-5 pb-5 pt-md-10 pb-md-10">
      <div className="container">
        <h2 className="text-mega text-grey mb-6 mb-md-11">The Pandev Law Difference</h2>
        <div className="row">
          <div className="col-lg-4 mb-4 mb-lg-0">
            <div className="container-about">
              <StaticImage
                className="lazy mb-5 mb-md-6"
                width={68}
                height={68}
                src="../../assets/img/icons/bank.svg"
                alt=""
              />
              <h3 className="text-headline mb-5">Perspective</h3>
              <p className="text-body">
                <strong>
                  My unique personal and professional experiences have given me a
                  one-of-a-kind perspective that results in the highest quality legal
                  representation.
                </strong>
                <br />
                <br />I lived in various European countries and Canada before immigrating
                to the US. This means that I have been in your shoes. I have personally
                experienced moving to a new country and the difficulties of the US
                immigration system. I understand your fears and anxieties, and use my
                empathy to provide top quality legal representation to you.
                <br />
                <br />I am fluent in Russian, German, Hungarian, and Bulgarian. I am
                looking forward to advising you in your native language.
              </p>
            </div>
          </div>
          <div className="col-lg-4 mb-4 mb-lg-0">
            <div className="container-about">
              <StaticImage
                className="lazy mb-5 mb-md-6"
                width={68}
                height={68}
                src="../../assets/img/icons/books.svg"
                alt=""
              />
              <h3 className="text-headline mb-5">Experience</h3>
              <p className="text-body">
                <strong>
                  Prior to forming Pandev Law, I served as Trial Attorney at the US
                  Department of Justice, Office of Immigration Litigation in Washington,
                  DC.
                </strong>
                <br />
                <br />
                As Trial Attorney, I represented government agencies – including the US
                Department of State, US Customs & Border Protection, and US Citizenship &
                Immigration Services – in litigation before Federal District Courts and
                Federal Courts of Appeal throughout the United States. Previously, I held
                positions with the US Department of Labor, the Atlanta Immigration Court,
                the New York office of a large international law firm, a boutique
                Washington, D.C. immigration firm, and the world's largest immigration
                firm.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="container-about">
              <StaticImage
                className="lazy mb-5 mb-md-6"
                width={68}
                height={68}
                src="../../assets/img/icons/scale.svg"
                alt=""
              />
              <h3 className="text-headline mb-5">Ability</h3>
              <p className="text-body">
                <strong>
                  My government service experience gives me the unique ability to analyze
                  issues from the government’s perspective while advocating for you.
                </strong>
                <br />
                <br />
                This is highly valuable when I advise on compliance matters, or when
                litigating unfair decisions issued by agencies, such as US Citizenship &
                Immigration Services and US Customs & Border Protection. I rely on my
                federal court litigation experience to appeal and challenge agency
                decisions, expedite long pending cases, and resolve business disputes
                before courts and other tribunals.
                <br />
                <br />I prioritize client service above all else. I am honored that you
                trust me with your legal issues and I promise to always treat you as a
                valued member of the Pandev Law family.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Usp;
