import React from 'react';

function Summary() {
  return (
    <div className="section pt-5 pb-5 pt-md-10 pb-md-10">
      <div className="container">
        <h2 className="text-mega text-grey mb-6 mb-md-8">Summary</h2>
        <div className="row">
          <div className="col-lg-5 mb-6 mb-lg-0">
            <div className="container-summary mb-6">
              <h3 className="text-headline mb-4 mb-md-5">Bar Admissions</h3>
              <ul className="list-bullets">
                <li>New York</li>
                <li>South Carolina</li>
                <li>US Court of International Trade</li>
              </ul>
            </div>
            <div className="container-summary mb-6">
              <h3 className="text-headline mb-4 mb-md-5">Education</h3>
              <ul className="list-bullets">
                <li>
                  J.D., cum laude, University of Georgia School of Law
                  <ul className="list-small">
                    <li>
                      Articles Editor, The Georgia Journal of International and
                      Comparative Law
                    </li>
                  </ul>
                </li>
                <li>
                  B.A., McGill University, Russian & Political Science focusing on
                  International Relations
                </li>
              </ul>
            </div>
            <div className="container-summary mb-6">
              <h3 className="text-headline mb-4 mb-md-5">Languages</h3>
              <ul className="list-bullets">
                <li>Russian</li>
                <li>German</li>
                <li>Hungarian</li>
                <li>Bulgarian</li>
              </ul>
            </div>
            <div className="container-summary">
              <h3 className="text-headline mb-4 mb-md-5">
                Memberships &amp; Affiliations
              </h3>
              <ul className="list-bullets">
                <li>American Immigration Lawyers Association</li>
                <li>New York State Bar Association</li>
                <li>South Carolina Bar</li>
                <li>Baltic American Chamber of Commerce</li>
                <li>Customs &amp; International Trade Bar Association</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="container-summary">
              <h3 className="text-headline mb-4 mb-md-5">
                Speaking Engagements &amp; Publications
              </h3>
              <ul className="list-bullets">
                <li className="mb-lg-4">
                  <a
                    className="link-primary"
                    style={{ wordBreak: 'break-all' }}
                    href="https://www.justice.gov/usao/page/file/984701/download"
                    target="_blank"
                    rel="noopener noreferrer">
                    Editor, &ldquo;Civil Immigration Enforcement and the Office of
                    Immigration Litigation,&rdquo; US Attorney&rsquo;s Bulletin,
                    Washington, D.C. (July 2017)
                  </a>
                </li>
                <li className="mb-lg-4">
                  Presenter, &ldquo;Employment and Immigration Seminar,&rdquo; Clemson
                  University, Clemson, South Carolina (August 2017)
                </li>
                <li className="mb-lg-4">
                  <a
                    className="link-primary"
                    style={{ wordBreak: 'break-all' }}
                    href="https://www.youtube.com/watch?v=bl96GRvWK2U"
                    target="_blank"
                    rel="noopener noreferrer">
                    Speaker, &ldquo;All You Need to Know About Moving to the US,&rdquo;
                    iPleaders Webinar, Kolkata, India (March 2018)
                  </a>
                </li>
                <li className="mb-lg-4">
                  Panelist, &ldquo;Social Justice Forum,&rdquo; St. John&rsquo;s
                  University, Queens, New York (March 2018 &amp; 2019)
                </li>
                <li className="mb-lg-4">
                  <a
                    className="link-primary"
                    style={{ wordBreak: 'break-all' }}
                    href="https://docs.wixstatic.com/ugd/c092d5_8b38d866b9934de0a74a68388a98a89e.pdf"
                    target="_blank"
                    rel="noopener noreferrer">
                    Presenter, &ldquo;U.S. Immigration Policy, Global Migration – An
                    Application to the Sports Industry,&rdquo; Global Awareness Society
                    International 27th Annual Conference, Atlanta, Georgia (May 2018)
                  </a>
                </li>
                <li className="mb-lg-4">
                  <a
                    className="link-primary"
                    href="http://universityseminars.columbia.edu/seminars/law-politics/"
                    target="_blank"
                    rel="noopener noreferrer">
                    Presenter, &ldquo;US Immigration Law – Dispelling the Myths,&rdquo;
                    Columbia University – Law & Politics Faculty Seminar, New York, New
                    York (October 2018)
                  </a>
                </li>
                <li className="mb-lg-4">
                  Presenter, &ldquo;Forgotten or Not Needed? Human Talent Across
                  Borders,&rdquo; Global Awareness Society International 28th Annual
                  Conference, Marrakech, Morocco (May 2019)
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Summary;
