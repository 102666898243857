import React from 'react';
import Layout from '../components/layout';
import Hero from '../components/about/hero';
import Summary from '../components/about/summary';
import Usp from '../components/about/usp';
import Testimonials from '../components/common/testimonials';
import CtaDeck from '../components/common/cta-deck';

const headMeta = {
  title: 'About | Pandev Law | Business & Immigation Law Firm',
  description:
    'Pandev Law is an experienced Immigration Law Firm helping individuals, companies, families and investors in business and immigration law matters.',
  keywords:
    'immigration law firm USA,immigration lawyers,immigration law services,immigration attorney,immigration legal services',
};

const schema = `{  
	"@context": "https://schema.org/",
	"@type": "Person",
	"name": "Adrian Pandev",
	"url": "https://www.pandevlaw.com/about/",
	"image": "https://www.pandevlaw.com/static/7d74ad9d3a8260dacc4310d1c74cbf10/d2cda/pandev-photo.webp",
	"sameAs": [
		"https://www.facebook.com/pandevlaw/",
		"https://twitter.com/pandevlaw/",
		"https://www.instagram.com/pandevlaw/",
		"https://www.youtube.com/channel/UCb5iF18tpdSkGGTY-JTR7pg/featured",
		"https://www.linkedin.com/company/pandevlaw/"
	],
	"jobTitle": "Principal attorney at Pandev Law",
	"worksFor": {
		"@type": "Organization",
		"name": "Pandev Law"
	}  
}`;

function About({ location }) {
  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema}>
      <Hero />
      <Usp />
      <Summary />
      <Testimonials />
      <CtaDeck />
    </Layout>
  );
}

export default About;
