import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

function Hero() {
  return (
    <div className="section pt-5 pb-5 pt-md-10 pb-md-10">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <StaticImage
              className="img-fluid d-none d-lg-block"
              src="../../assets/img/pandev-photo.jpg"
              alt="Adrian Pandev immigration lawyer USA"
              placeholder="none"
              loading="eager"
            />
          </div>
          <div className="col-lg-7 pl-lg-6">
            <h1 className="text-hero mb-5 mb-md-10">Who I am</h1>
            <p className="text-lead mb-5 mb-md-6">
              <strong>
                I am a business and immigration lawyer representing clients throughout the
                US and worldwide.
              </strong>
              <br />
              <br />
              My immigration practice focuses on investor and employment-based
              immigration, particularly EB-5 Investors and E-2 Treaty Investors, H-1B
              Specialty Workers, and L-1 Intracompany Transferees.
              <br />
              <br />
              My business practice focuses on advising entrepreneurs, small, and medium
              sized businesses in formation and governance, mergers and acquisitions,
              commercial transactions, and financing, including venture capital.
              <br />
              <br />I represent startups and established organizations in connection with
              visa sponsorship for foreign employees and immigration compliance, including
              Form I-9 and E-Verify. I have extensive experience with the employment-based
              permanent residence process, including PERM Labor Certifications, EB-2
              National Interest Waivers, and EB-1 petitions for Multinational Managers and
              Individuals of Extraordinary Ability, including academics, researchers,
              scientists, artists, and athletes. I also help unite families through
              marriage, fiancé and other family-based petitions. I have developed a niche
              in representing educational institutions, including primary schools and
              colleges.
              <br />
              <br />I regularly advise on cross-border transactions and represent foreign
              companies seeking to enter the US market. I advise on import and export
              compliance and help obtain relief for alleged customs law violations. I am
              fascinated by blockchain technology and represent blockchain startups and
              cryptocurrency investors.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
